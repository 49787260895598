import {useTranslation} from "react-i18next";

export function useFormatNumber() {
    const {t} = useTranslation();
    return (n?: number) => {
        if(n == null || isNaN(n)) {
            return t('N_A');
        }
        
        const oneMillion = 1000000;
        return n >= oneMillion
            ? parseFloat((n / oneMillion).toFixed(2)) + t('MillionM')
            : n.toLocaleString();
    };
}

export function useFormatPercent() {
    return (percentDecimal: number) => parseFloat(Math.round(percentDecimal * 100).toFixed(2)) + '%';
}