import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useAppMsal } from './auth.msal';
import AccessForbidden from '../../appUIFramework/permissions/AccessForbidden';

export enum UserRole {
  User = 'User',
  Admin = 'Admin',
  RemoteSupportAccessP10 = 'RemoteSupportAccessP10'
}

interface IAppClaims {
  roles: UserRole[];
  userId: string;
}

const ClaimsContext = createContext<IAppClaims>({ roles: [], userId: ''});

export function useAppClaims(): IAppClaims {
  const claims = useContext(ClaimsContext);
  if (!claims) {
    throw new Error('useAppClaims must be used within a ClaimsProvider');
  }
  return claims;
}

export function ClaimsProvider({ children }: { children: ReactNode }) {
  const msal = useAppMsal();
  const [claims, setClaims] = useState<IAppClaims | null>(null);
  const [failedToGetClaims, setFailedToGetClaims] = useState(false);
  const [initCompleted, setInitCompleted] = useState(false);

  useEffect(() => {
    msal.acquireTokenSilent()
      .then(({ idTokenClaims }) => {
        const roles = (idTokenClaims as any).roles as UserRole[];
        if((roles.includes(UserRole.Admin) || roles.includes(UserRole.RemoteSupportAccessP10)) && !roles.includes(UserRole.User))
        {
          roles.push(UserRole.User);
        }

        if(!roles || roles.length === 0) {
          setFailedToGetClaims(true);
        } else {
          setClaims({ 
            roles: roles,
            userId: (idTokenClaims as any).oid as string,
          });
        }
      })
      .catch((e) => {
        console.error(e);
        setFailedToGetClaims(true);
      })
      .finally(() => {
        setInitCompleted(true);
      });
  }, [msal]);

  return (
    <>
      {initCompleted && (!failedToGetClaims
        ? (claims && (
          <ClaimsContext.Provider value={claims}>
            {children}
          </ClaimsContext.Provider>
        ))
        : <AccessForbidden />)}
    </>
  );
}
