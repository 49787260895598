import {Urls} from "../backend/urls";
import {useSWRAndHandleErrors} from "./swr/useSWRAndHandleErrors";
import {httpGetJson, httpPostJson, httpPut} from "../backend/http/http";

export interface ISite {
  id: string;
  siteName: string;
  companyId: string;
}

export function useSites(companyId?: string): { sites: ISite[] } {
  const url = companyId ? Urls.Sites(companyId) : undefined;
  const {data} = useSWRAndHandleErrors<ISite[]>(url, httpGetJson);

  return {
    sites: data || []
  };
}

export interface ITransferSitesDto {
  toCompanyId: string;
  fromCompanyId: string;
  siteIds: string[];
}
export async function transferSites(body: ITransferSitesDto): Promise<void> {
  await httpPut(Urls.SitesTransfer, body);
}
