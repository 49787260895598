import {useTranslation} from "react-i18next";
import {useGrowthPercents, useTotalCount} from "../../../../shared/appBackend/useMetrics";
import CompanyIcon from "../../../../assets/icons/home page icons/entry-analytics-company-icon.svg";
import AppAnalyticsCountIcon from "./AppAnalyticsCountIcon";
import AppAnalyticsGrowth from "./AppAnalyticsGrowth";
import AppAnalyticsSmallIcon from "./AppAnalyticsSmallIcon";
import {
  ReactComponent as CompaniesIcon
} from '../../../../assets/icons/home page icons/entry-analytics-companies-icon.svg';
import {useContext} from "react";
import {EntryAnalyticsContext} from "../EntryAnalyticsContext";

export default function CompaniesWidget() {
  const {t} = useTranslation();

  const {entryRegion} = useContext(EntryAnalyticsContext);
  const totalCount = useTotalCount(entryRegion);
  const growthPercents = useGrowthPercents(entryRegion);

  return <div className="app-home-widget app-analytics-widget app-w-50pcnt">
    <div className="app-home-widget-left">
      <div className="app-home-widget-title-text">
        {t('Companies')}
      </div>
      <div className="app-analytics-widget-big-icon-container">
        <div className="app-analytics-widget-big-icon app-analytics-widget-companies-big-icon">
          <AppAnalyticsCountIcon icon={<CompaniesIcon/>}
                                 count={totalCount?.companiesCount}
                                 title={t('Companies')}/>
        </div>
      </div>
      <div className="app-analytics-widget-footer">
        <AppAnalyticsGrowth growthPercents={growthPercents?.companiesCount}/>
      </div>
    </div>
    <div className="app-home-widget-right">
      <div style={{visibility: "hidden"}}>Spacer</div>
      <div className="app-d-flex app-align-items-center app-justify-content-center app-w-100pcnt">
        <div className="app-analytics-widget-body-small-icons app-w-212">
          <AppAnalyticsSmallIcon icon={<img src={CompanyIcon}
                                 alt={'Icon'}/>}
                                 count={totalCount?.paymentAdminsCount}
                                 title={t('PaymentAdmins')}/>
          <AppAnalyticsSmallIcon icon={<img src={CompanyIcon}
                                 alt={'Icon'}/>}
                                 count={totalCount?.systemAdminsCount}
                                 title={t('SystemAdmins')}/>
        </div>
      </div>
      <div className="app-d-flex app-align-items-center app-justify-content-center app-w-100pcnt">
        <div className="app-analytics-widget-body-small-icons app-w-212">
          <AppAnalyticsSmallIcon icon={<img src={CompanyIcon}
                                 alt={'Icon'}/>}
                                 count={totalCount?.siteAdminsCount}
                                 title={t('SiteAdmins')}/>
          <div style={{visibility: "hidden", width: '64px'}}>Spacer</div>
       </div>
       </div>
    </div>
  </div>;
}
