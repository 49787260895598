import { AppPopupLayout } from '../../shared/appUIFramework/popup/AppPopupLayout';
import LoadingBubbles from '../../assets/loading-bubbles.svg';
import './AppOperationProcessingPopup.scss';
import { useTranslation } from 'react-i18next';

export default function AppOperationProcessingPopup(
  {
    titleKey,
    bodyKey = 'OperationBeingProcessing',
  }: {
    titleKey: string;
    bodyKey?: string;
  },
) {
  const { t } = useTranslation();

  return (
    <AppPopupLayout
      headerTitle={titleKey}
      popupBody={
        <div className="app-d-flex app-flex-column app-align-items-center app-justify-content-space-around">
          <img
            className='app-show-loading-image'
            src={LoadingBubbles}
            alt='Loading...'
          />
          <span className='app-font-20 app-uppercase app-weight-600 app-text-align-center app-mt-30'>
            {t(bodyKey)}
          </span>
        </div>
      }
    />
  );
}
