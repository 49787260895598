import { useTranslation } from 'react-i18next';
import { ReactComponent as EmailIcon } from '../../../assets/icons/Text field icons/Email  icon.svg';
import { closePopup } from '../popup/AppPopup';
import { AppPopupLayout } from '../popup/AppPopupLayout';
import { useEffect } from 'react';

// that popup should never be shown, if it is shown, 99% it is a bug and need to contact development
let count = 0;

// should be called when the request success
export function resetRequestFailedPopupCount() {
  count = 0;
}

export default function AppRequestFailedPopup({titleKey = 'Popup_Error'}: {titleKey?: string}) {
  const { t } = useTranslation();
  const goBack = () => closePopup(true);
  const title = count > 1 ? 'SomethingWentWrongContactDevelopment' : 'SomethingWentWrongTryAgainLater';
  useEffect(() => {
    return () => { count++ };
  }, []);

  return (
    <AppPopupLayout
      headerTitle={titleKey}
      popupBody={(
        <div className="app-d-flex app-flex-column app-align-items-center app-justify-content-center">
          <EmailIcon className="app-w-160 app-h-128" />
          <span className="app-d-inline-block app-mw-500 app-pt-40">{ t(title) }</span>
        </div>
      )}
      buttons={(
        <>
          <button type="button" onClick={goBack} className="app-primary-button app-popup-right-button">
            { t('Ok') }
          </button>
        </>
      )}
    />
  );
}
