import {useTranslation} from "react-i18next";
import MonitorsIcon from "../../../../assets/icons/home page icons/Monitors.svg";
import PanelsIcon from "../../../../assets/icons/home page icons/Panels.svg";
import AppAnalyticsCountIcon from "./AppAnalyticsCountIcon";
import AppAnalyticsGrowth from "./AppAnalyticsGrowth";
import AppAnalyticsSmallIcon from "./AppAnalyticsSmallIcon";
import {ReactComponent as SystemsIcon} from '../../../../assets/icons/home page icons/Systems.svg';
import {useGrowthPercents, useTotalCount} from "../../../../shared/appBackend/useMetrics";
import {useContext} from "react";
import {EntryAnalyticsContext} from "../EntryAnalyticsContext";

export default function SitesWidget() {
  const {t} = useTranslation();

  const {entryRegion} = useContext(EntryAnalyticsContext);
  const totalCount = useTotalCount(entryRegion);
  const growthPercents = useGrowthPercents(entryRegion);

  return <div className="app-home-widget app-analytics-widget">
    <div className="app-home-widget-left">
      <div className="app-home-widget-title-text">
        {t('Sites')}
      </div>
      <div className="app-analytics-widget-big-icon-container">
        <div className="app-analytics-widget-big-icon app-analytics-widget-systems-big-icon">
          <AppAnalyticsCountIcon icon={<SystemsIcon/>}
                                 count={totalCount?.sitesCount}
                                 title={t('Sites')}/>
        </div>
      </div>
      <div className="app-analytics-widget-footer">
        <AppAnalyticsGrowth growthPercents={growthPercents?.sitesCount}/>
      </div>
    </div>
    <div className="app-home-widget-right">
      <div style={{visibility: "hidden"}}>Spacer</div>
      <div className="app-analytics-widget-body-small-icons">
        <AppAnalyticsSmallIcon icon={<img src={MonitorsIcon}
                                          alt={'Icon'}/>}
                               count={totalCount?.monitorsCount}
                               title={t('Monitors')}/>
        <AppAnalyticsSmallIcon icon={<img src={PanelsIcon}
                                          alt={'Icon'}/>}
                               count={totalCount?.panelsCount}
                               title={t('Panels')}/>
      </div>
      <div></div>
    </div>
  </div>;
}
