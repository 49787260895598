import {useState} from 'react';
import './EntryAnalytics.scss';
import HomeShell from '../components/HomeShell';
import {ServiceRegion} from "../../../shared/appBackend/useMetrics";
import RegionsDropdown from "../components/RegionsDropdown";
import CompaniesWidget from './components/CompaniesWidget';
import SitesWidget from './components/SitesWidget';
import UsersWidget from './components/UsersWidget';
import CallRateWidget from './components/CallRateWidget';
import {EntryAnalyticsContext} from './EntryAnalyticsContext';
import { EntryRegion } from '../../../shared/backend/urls';

function serviceRegionToEntryRegion(serviceRegion: ServiceRegion): EntryRegion | null {
  const map: Record<ServiceRegion, EntryRegion | null> = {
    [ServiceRegion.All]: null,
    [ServiceRegion.UK]: EntryRegion.UnitedKingdom,
    [ServiceRegion.NL]: EntryRegion.Netherlands,
    [ServiceRegion.US]: EntryRegion.UnitedStates,
    [ServiceRegion.FR]: EntryRegion.France,
    [ServiceRegion.DE]: EntryRegion.Denmark,
  }
  
  return map[serviceRegion];
}

export default function EntryAnalytics() {
  const [region, setRegion] = useState<ServiceRegion>(ServiceRegion.All);

  return <HomeShell activeTab="entry-analytics">
    <RegionsDropdown region={region} setRegion={setRegion}/>
    <EntryAnalyticsContext.Provider value={{
      entryRegion: serviceRegionToEntryRegion(region)
    }}>
      <div className="app-analytics-widgets">
        <CompaniesWidget/>
        <SitesWidget/>
      </div>
      <div className="app-d-flex app-w-50pcnt app-gap-30">
        <UsersWidget/>
        <CallRateWidget/>
      </div>
    </EntryAnalyticsContext.Provider>
  </HomeShell>;
}
