import { ReactNode } from 'react';
import HelpIcon from '../../../assets/icons/Navigation ribbon icons/Help Icon.svg';
import { showPopup } from '../popup/AppPopup';
import AppHelpPopup from './AppHelpPopup';

export default function AppContentToolbar({ children }: { children: ReactNode }) {
  async function showHelpPopup() {
    await showPopup(<AppHelpPopup />);
  }

  return (
    <div
      className="app-content-toolbar app-d-flex app-align-items-center app-justify-content-between app-h-80"
    >
      <div className="app-toolbar-breadcrumps">
        { children }
      </div>
      <div
        className="app-d-flex app-align-items-center app-position-relative"
      >
        <div className="app-mr-20 app-cursor-pointer" role="button" tabIndex={0} onKeyDown={showHelpPopup} onClick={showHelpPopup}>
          <img
            src={HelpIcon}
            alt="Help"
          />
        </div>
      </div>
    </div>
  );
}
