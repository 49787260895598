import {useState} from "react";

interface ITransferSystemState {
  currentCompanyName?: string;
  newCompanyName?: string;
  currentCompanyId?: string;
  newCompanyId?: string;
  sitesToTransferNames: string[];
  sitesSearchString: string;
}

export function useTransferSitesState() {
  const [state, setState] = useState<ITransferSystemState>({
    currentCompanyName: '',
    sitesToTransferNames: [],
    sitesSearchString: '',
  });

  return {
    state,
    setCurrentCompany: (installer: string, companyId: string) => {
      setState({
        ...state,
        currentCompanyName: installer,
        currentCompanyId: companyId,
        sitesToTransferNames: [],
        sitesSearchString: '',
      })
    },
    setNewCompany: (installer: string, companyId: string) => {
      setState({
        ...state,
        newCompanyId: companyId,
        newCompanyName: installer
      })
    },
    setSystemsSearchString: (search: string) => {
      setState({
        ...state,
        sitesSearchString: search
      })
    },
    toggleAllSitesToTransferNames: (systems: string[]) => {
      const allSystemsSelected = state.sitesToTransferNames.length === systems.length;
      setState({
        ...state,
        sitesToTransferNames: allSystemsSelected ? [] : systems
      })
    },
    toggleSiteToTransferName: (system: string) => {
      const systemIndex = state.sitesToTransferNames.indexOf(system);
      setState({
        ...state,
        sitesToTransferNames: systemIndex === -1
          ? [...state.sitesToTransferNames, system]
          : state.sitesToTransferNames.filter((p) => p !== system)
      })
    },
  }
}
