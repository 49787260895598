import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'wouter';
import AppContentToolbar from '../../../shared/appUIFramework/components/AppContentToolbar';
import './HomeShell.scss';
import { useIsAdmin } from '../../../shared/appUIFramework/permissions/AppPermissionGuard';

type TabType = 'entry-analytics' | 'service-health' | 'whitelist' | 'billing' | 'diagnostics' | 'blockedAccounts';

interface ITabModel {
  titleKey: string;
  key: TabType;
  to: string;
}

export default function HomeShell ({ activeTab, children }: { children?: ReactNode, activeTab: TabType }) {
  const { t } = useTranslation();

  const isAdmin = useIsAdmin();

  const tabs = [
    { titleKey: 'EntryAnalytics', key: 'entry-analytics', to: `/home/entry-analytics` },
    { titleKey: 'ServiceHealth', key: 'service-health', to: `/home/service-health` },
    { titleKey: 'Diagnostics', key: 'diagnostics', to: `/home/diagnostics` },
    { titleKey: 'UserWhiteList', key: 'whitelist', to: `/home/whitelist`, forbidden: !isAdmin },
    { titleKey: 'BillingWhiteList', key: 'billing', to: `/home/billing`, forbidden: !isAdmin },
    { titleKey: 'BlockedAccounts', key: 'blockedAccounts', to: `/home/blocked-accounts`, forbidden: !isAdmin },
  ].filter(p => !p.forbidden) as ITabModel[];

  return (
    <>
      <AppContentToolbar>
        <span className="app-toolbar-breadcrump">
            { t("Home") }
        </span>
      </AppContentToolbar>
      <div className="app-tabs">
        { tabs.map(({ titleKey, key, to }) => (
          <Link
            className={`app-tab ${key === activeTab ? 'app-tab-active' : ''}`}
            key={key}
            to={to}
          >
            { t(titleKey) }
          </Link>
        ))}
      </div>
      <div className="app-content">
        { children }
      </div>
    </>
  );
}
