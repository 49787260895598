import {useTranslation} from "react-i18next";
import AppAnalyticsCountIcon from "./AppAnalyticsCountIcon";
import {ReactComponent as CallBigIcon} from '../../../../assets/icons/home page icons/CallBig.svg';
import {useTotalCount} from "../../../../shared/appBackend/useMetrics";
import {useContext} from "react";
import {EntryAnalyticsContext} from "../EntryAnalyticsContext";

export default function CallRateWidget() {
  const {t} = useTranslation();
  const {entryRegion} = useContext(EntryAnalyticsContext);
  const totalCount = useTotalCount(entryRegion);
  return <div className="app-home-widget app-home-widget-call-rate">
    <div className="app-home-widget-title app-mb-46">
      <span className="app-home-widget-title-text">{t('Calls')}</span>
    </div>
    <div className="app-home-users-icon app-mb-25">
      <div className="app-analytics-widget-big-icon-container app-no-border-right">
        <div className="app-analytics-widget-big-icon app-analytics-widget-calls-big-icon">
          <AppAnalyticsCountIcon icon={<CallBigIcon/>}
                                 count={totalCount?.callCount}
                                 title={t('ThisMonth')}/>
        </div>
      </div>
    </div>
  </div>
}
