import {ReactNode} from "react";
import {useFormatNumber} from "../../../../shared/formatters/formatNumber";

export default function AppAnalyticsSmallIcon({icon, count, title}: { icon: ReactNode, count?: number, title: string }) {
  const formatNumber = useFormatNumber();
  return <div className="app-analytics-small-icon">
    <div className="app-analytics-small-icon-icon">
      {icon}
    </div>
    <div className="app-analytics-small-icon-count">
      {formatNumber(count)}
    </div>
    <div className="app-analytics-small-icon-title">
      {title}
    </div>
  </div>;
}