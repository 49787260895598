import {useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as PeopleIcon} from '../../assets/icons/InstallerPersonIcon.svg';
import {ReactComponent as SystemsIcon} from '../../assets/icons/home page icons/Systems.svg';
import {ReactComponent as SearchIcon} from '../../assets/icons/Search icon.svg';
import {ReactComponent as CrossIcon} from '../../assets/icons/Cross icon.svg';
import {ReactComponent as ArrowIcon} from '../../assets/icons/Arrow icon.svg';
import AppContentToolbar from '../../shared/appUIFramework/components/AppContentToolbar';
import AppRoundCheckbox from '../../shared/appUIFramework/components/AppRoundCheckbox';
import AppSelect from '../../shared/appUIFramework/components/AppSelect';
import './TransferSite.scss';
import {useTransferSitesState} from './transferSiteReducer';
import {showPopup} from "../../shared/appUIFramework/popup/AppPopup";
import TransferSiteConfirmationPopup from "./TransferSiteConfirmationPopup";
import {useLocation} from "wouter";
import TransferSiteSuccessPopup from "./TransferSiteSuccessPopup";
import {ICompany} from "../../shared/appBackend/useCompanies";
import {transferSites, useSites} from "../../shared/appBackend/useSites";
import { useLazyLoad } from '../../shared/appBackend/useLazyLoad';
import { Urls } from '../../shared/backend/urls';

export default function TransferSite() {
  const {t} = useTranslation();
  const [, setLocation] = useLocation();
  
  const {
    state,
    setSystemsSearchString,
    setNewCompany,
    setCurrentCompany,
    toggleAllSitesToTransferNames,
    toggleSiteToTransferName
  } = useTransferSitesState();
  
  const [companiesLeftSearchString, setCompaniesLeftSearchString] = useState('');
  const {items: companiesLeft, loadMore: loadMoreCompaniesLeft, lastPageLoaded: lastPageOfCompaniedLeftLoaded, loading: isCompaniesLeftLoading } = useLazyLoad<ICompany>(Urls.Companies, {
    search: companiesLeftSearchString
  });
  const companiesLeftNameIdMap: { [key: string]: string } = companiesLeft.reduce((acc, curr) => ({
    ...acc,
    [curr.companyName]: curr.id
  }), {});
  const companiesLeftNames = companiesLeft.map(p => p.companyName);

  const [companiesRightSearchString, setCompaniesRightSearchString] = useState('');
  const {items: companiesRight, loadMore: loadMoreCompaniesRight, lastPageLoaded: lastPageOfCompaniedRightLoaded, loading: isCompaniesRightLoading } = useLazyLoad<ICompany>(Urls.Companies, {
    search: companiesRightSearchString
  });
  const companiesRightNameIdMap: { [key: string]: string } = companiesRight.reduce((acc, curr) => ({
    ...acc,
    [curr.companyName]: curr.id
  }), {});
  const companiesRightNames = companiesRight.map(p => p.companyName);

  const currentInstallers = companiesLeftNames;
  const newInstallers = companiesRightNames.filter(p => p !== state.currentCompanyName);

  const {sites} = useSites(state.currentCompanyId);
  const sitesNameIdMap: { [key: string]: string } = sites.reduce((acc, curr) => ({...acc, [curr.siteName]: curr.id}), {});
  const siteNames = sites.map(p => p.siteName);
  const currentCompanySiteNames = state.currentCompanyName ? siteNames.filter(p => p.toLowerCase().includes(state.sitesSearchString.toLowerCase())) : [];
  const isSaveDisabled = () => !state.newCompanyId || !state.currentCompanyId || state.sitesToTransferNames.length === 0;
  const transferSystemContainerRef = useRef<HTMLDivElement>(null);

  const redirectToHome = () => {
    setLocation('/home');
  };
  const submitTransfer = async () => {
    if(!state.currentCompanyId || !state.newCompanyId) {
      console.error('Current or new company id is not set');
      return;
    }

    const isMultipleSites = state.sitesToTransferNames.length > 1;
    const siteNameIfNotMultiple = isMultipleSites ? undefined : state.sitesToTransferNames[0];
    const isSuccess = await showPopup(<TransferSiteConfirmationPopup isNotMultipleSites={siteNameIfNotMultiple}/>)
    if (isSuccess) {
      await transferSites({
        toCompanyId: state.newCompanyId,
        siteIds: state.sitesToTransferNames.map(p => sitesNameIdMap[p]),
        fromCompanyId: state.currentCompanyId
      });
      await showPopup(<TransferSiteSuccessPopup singleSite={siteNameIfNotMultiple}
                                                fromInstaller={state.currentCompanyName}
                                                toInstaller={state.newCompanyName}/>);
      redirectToHome();
    }
  }
  
  return (
    <>
      <AppContentToolbar>
        <span className="app-toolbar-breadcrump">{t('TransferSite')}</span>
      </AppContentToolbar>
      <div className="app-content app-transfer-site-page">
        <div className="app-transfer-site-title">
          {t('TransferDetails')}
        </div>
        <div className="app-transfer-site-details">
          <div className="app-transfer-site-two-column-container">
            <div className="app-transfer-site-container-left">
              <div className="app-w-100pcnt">
                <div className="app-form-control">
                  <div className="app-form-control-label app-form-control-label-with-prefix">
                    {t('CurrentCompany')}
                  </div>
                  <div className="app-form-control-input">
                    <div className="app-form-control-prefix">
                      <PeopleIcon/>
                    </div>
                    <AppSelect
                      className="app-form-input"
                      outlineSearch
                      outlineSearchPlaceholder={t('SearchCompanies')}
                      onOutlineSearchChange={e => {
                        setCompaniesLeftSearchString(e.target.value);
                      }}
                      onClosed={() => {
                        setCompaniesLeftSearchString('');
                      }}
                      onOptionsScrolledToBottom={loadMoreCompaniesLeft}
                      areOptionsLoading={isCompaniesLeftLoading}
                      lastPageLoaded={lastPageOfCompaniedLeftLoaded}
                      showNoOptions
                      incomingValue={state.currentCompanyName}
                      options={currentInstallers}
                      placeholder={t('SelectCompany')}
                      onOptionSelected={companyName => setCurrentCompany(companyName, companiesLeftNameIdMap[companyName])}
                    />
                  </div>
                </div>
                <div className="app-form-control app-form-control-search app-mt-32px">
                  <div className="app-form-control-input app-transfer-site-list-left"
                       aria-disabled={!state.currentCompanyName}>
                    <div className="app-form-control-search-icon" aria-disabled={!state.currentCompanyName}>
                      <SearchIcon/>
                    </div>
                    <input type="text"
                           placeholder={t('SearchSites')}
                           disabled={!state.currentCompanyName}
                           value={state.sitesSearchString}
                           className="app-transfer-list-search"
                           onChange={(event) => setSystemsSearchString(event.target.value)}/>
                  </div>
                </div>
              </div>
            </div>
            <div className="app-transfer-site-container-right app-h-100pcnt">
              <div className="app-w-100pcnt">
                <div className="app-form-control">
                  <div className="app-form-control-label app-form-control-label-with-prefix">
                    {t('NewCompany')}
                  </div>
                  <div className="app-form-control-input">
                    <div className="app-form-control-prefix">
                      <PeopleIcon/>
                    </div>
                    <div className="app-state-wrapper"
                         aria-disabled={!state.currentCompanyName}>
                      <AppSelect
                        className="app-form-input"
                        outlineSearch
                        outlineSearchPlaceholder={t('SearchCompanies')}
                        onOutlineSearchChange={e => {
                          setCompaniesRightSearchString(e.target.value);
                        }}
                        onClosed={() => {
                          setCompaniesRightSearchString('');
                        }}
                        onOptionsScrolledToBottom={loadMoreCompaniesRight}
                        lastPageLoaded={lastPageOfCompaniedRightLoaded}
                        areOptionsLoading={isCompaniesRightLoading}
                        showNoOptions
                        incomingValue={state.newCompanyName}
                        options={newInstallers}
                        placeholder={t('SelectCompany')}
                        disabled={!state.currentCompanyName}
                        onOptionSelected={companyName => setNewCompany(companyName, companiesRightNameIdMap[companyName])}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="app-transfer-site-list-container">
            <div className="app-transfer-site-list-left">
              {state.currentCompanyName && <div className="app-transfer-site-toggle-all-systems"
                                                onClick={() => toggleAllSitesToTransferNames(currentCompanySiteNames)}>
                  <AppRoundCheckbox
                      checked={state.sitesToTransferNames.length > 0 && state.sitesToTransferNames.length === sites.length}/>
                  <div>{t('SelectAll')}</div>
              </div>}
              <div className="app-form-control app-form-control-select-system">
                <div className="app-form-control-input">
                  <div className="app-form-control-prefix">
                    <SystemsIcon/>
                  </div>
                  <div className="app-transfer-site-container" ref={transferSystemContainerRef}>
                    {currentCompanySiteNames.map((system) => (
                      <div className="app-transfer-site-checkbox-container"
                           key={system}
                           onClick={() => toggleSiteToTransferName(system)}>
                        <AppRoundCheckbox
                          checked={state.sitesToTransferNames.indexOf(system) > -1}/>
                        <SystemsIcon/>
                        <span>{system}</span>
                      </div>
                    ))
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="app-transfer-site-list-center">
              <ArrowIcon/>
            </div>
            <div className="app-transfer-site-list-right">
              <div className="app-form-control app-form-control-select-system">
                <div className="app-form-control-input">
                  <div className="app-form-control-prefix">
                    <SystemsIcon/>
                  </div>
                  <div className="app-transfer-site-container">
                    {state.newCompanyName && state.sitesToTransferNames.map((system) => (
                      <div key={system}
                           className="app-transfer-site-new-installer-system-container">
                        <div>
                          <SystemsIcon/>
                          <span>{system}</span>
                        </div>
                        <CrossIcon className="app-transfer-site-cross-icon"
                                   onClick={() => toggleSiteToTransferName(system)}/>
                      </div>
                    ))
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="app-transfer-site-buttons">
          <button type="button" className="app-primary-button" disabled={isSaveDisabled()} onClick={submitTransfer}>
            {t('Transfer')}
          </button>
        </div>
      </div>
    </>
  );
}
