import {EntryRegion, Urls} from "../backend/urls";
import {useSWRAndHandleErrors} from "./swr/useSWRAndHandleErrors";
import {httpGetJson} from "../backend/http/http";

export enum ServiceRegion {
  All = 0,
  UK = 1,
  NL,
  US,
  FR,
  DE
}

export interface IAdminPortalAnalytics {
  companiesCount: number;
  sitesCount: number;
  mobileUsersCount: number;
  paymentAdminsCount: number;
  siteAdminsCount: number;
  systemAdminsCount: number;
  panelsCount: number;
  monitorsCount: number;
  callCount: number;
}

export function useTotalCount(region: EntryRegion | null) {
  const url = Urls.TotalCount(region);
  const {data} = useSWRAndHandleErrors<IAdminPortalAnalytics>(url, httpGetJson);

  return data;
}

function getGrowth(originalCount: number, countChangedBy: number) {
  if (originalCount === 0) {
    return countChangedBy > 0 ? 1 : 0;
  }
  return countChangedBy / originalCount;
}

export function useGrowthPercents(entryRegion: EntryRegion | null): IAdminPortalAnalytics | null {
  const totalCount = useTotalCount(entryRegion);
  const {data: countChangedByInLastMonth} = useSWRAndHandleErrors<IAdminPortalAnalytics>(Urls.ChangesForLastMonth(entryRegion), httpGetJson);
  if (!countChangedByInLastMonth || !totalCount) {
    return null;
  } else {
    return Object.entries(countChangedByInLastMonth).reduce((acc, [key, value]) => {
      const castedKey = key as keyof IAdminPortalAnalytics;
      const castedValue = value as number;
      const castedAcc = acc as IAdminPortalAnalytics;
      return {
        ...castedAcc,
        [castedKey]: getGrowth(totalCount[castedKey], castedValue)
      };
    }, {}) as IAdminPortalAnalytics;
  }
}
