import { useTranslation } from 'react-i18next';
import MaintenanceEmailsShell from './MaintenanceEmailsShell';
import { useRef, useState } from 'react';
import AppSelect from '../../shared/appUIFramework/components/AppSelect';
import { ReactComponent as CalendarIcon } from '../../assets/icons/Calendar.svg';
import { ReactComponent as CalendarGrayIcon } from '../../assets/icons/calendar_grey.svg';
import { ReactComponent as EmailIcon } from '../../assets/icons/Lefthand navigation icons/Email icon grey.svg';
import { ReactComponent as TimeIcon } from '../../assets/icons/Time from-to icon.svg';
import { ReactComponent as DurationIcon } from '../../assets/icons/Expected duration icon.svg';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './MaintenanceEmailsConfiguration.scss';
import AppOutsideListener from '../../shared/appUIFramework/components/AppOutsideListener';
import { EmailLogItemType, sendScheduledEmails } from '../../shared/appBackend/useEmailLog';
import { Formats, formatDate } from '../../shared/formatters/formatDate';
import { showPopup } from '../../shared/appUIFramework/popup/AppPopup';
import AppRequestFailedPopup from '../../shared/appUIFramework/components/AppRequestFailedPopup';

function useForm() {
  const [emailType, setEmailType] = useState('');
  const [date, setDate] = useState(new Date());
  const [timeFrom, setTimeFrom] = useState('');
  const [timeFromError, setTimeFromError] = useState('');
  const [timeFromBlurred, setTimeFromBlurred] = useState(false);
  const [timeToError, setTimeToError] = useState('');
  const [timeToBlurred, setTimeToBlurred] = useState(false);
  const [timeTo, setTimeTo] = useState('');
  let expectedDuration = '';
  if (timeTo && timeFrom && !timeFromError && !timeToError) {
    const timeFromParts = timeFrom.split(':');
    const timeToParts = timeTo.split(':');
    const from = new Date(0, 0, 0, parseInt(timeFromParts[0]), parseInt(timeFromParts[1]));
    const to = new Date(0, 0, 0, parseInt(timeToParts[0]), parseInt(timeToParts[1]));
    const diff = to.getTime() - from.getTime();
    const hours = Math.floor(diff / 1000 / 60 / 60);
    const minutes = Math.floor(diff / 1000 / 60) - hours * 60;
    if (hours > 0) {
      expectedDuration = `${hours}h ${minutes}m`;
    } else {
      expectedDuration = `${minutes}m`;
    }
  }

  const reset = () => {
    setEmailType('');
    setDate(new Date());
    setTimeFrom('');
    setTimeTo('');
  };

  const validateTimeValue = (value: string) => {
    const parts = value.split(':');
    if (parts.length !== 2) {
      return 'Time format should be hh:mm';
    }

    const hours = parseInt(parts[0]);
    if (isNaN(hours) || hours < 0 || hours > 23) {
      return 'Hours must be between 0 and 23';
    }

    const minutes = parseInt(parts[1]);
    if (isNaN(minutes) || minutes < 0 || minutes > 59) {
      return 'Minutes must be between 0 and 59';
    }

    return '';
  };

  return {
    emailType,
    date,
    timeFrom,
    timeTo,
    expectedDuration,
    setEmailType,
    setDate,
    setTimeFrom: (value: string) => {
      setTimeFrom(value);
      setTimeFromError(validateTimeValue(value));
    },
    setTimeTo: (value: string) => {
      setTimeTo(value);
      setTimeToError(validateTimeValue(value));
    },
    reset,
    timeFromError,
    timeToError,
    timeFromBlurred,
    setTimeFromBlurred,
    timeToBlurred,
    setTimeToBlurred,
    isValid: !timeFromError && !timeToError,
  };
}

export default function MaintenanceEmailsConfiguration() {
  const { t } = useTranslation();

  const form = useForm();

  const emailTypeTranslationKeyValueMap = useRef({
    [t('SoftwareUpgrade')]: EmailLogItemType.SoftwareUpgrade,
    [t('InfrastructureUpgrade')]: EmailLogItemType.InfrastructureUpgrade,
  });

  const emailTypeTranslations = useRef(Object.keys(emailTypeTranslationKeyValueMap.current));

  const [calendarVisible, setCalendarVisible] = useState(false);

  return (
    <MaintenanceEmailsShell activeTab='configuration'>
      <form
        onSubmit={ async e => {
          e.preventDefault();
          e.stopPropagation();

          if (!form.isValid) {
            return;
          }

          try {
            await sendScheduledEmails({
              date: form.date,
              emailType: emailTypeTranslationKeyValueMap.current[form.emailType],
              timeFrom: new Date(0, 0, 0, parseInt(form.timeFrom.split(':')[0]), parseInt(form.timeFrom.split(':')[1])),
              timeTo: new Date(0, 0, 0, parseInt(form.timeTo.split(':')[0]), parseInt(form.timeTo.split(':')[1])),
            });
          } catch (e) {
            await showPopup(<AppRequestFailedPopup />);
          }
        } }
        className='app-form app-d-flex app-flex-column app-gap-42 app-w-50pcnt app-px-44 app-pt-40'
      >
        <div className='app-form-control'>
          <div className='app-form-control-label app-form-contol-label-with-prefix-at-text-start'>
            <span>{ t('EmailType') }</span>
            <span className="app-text-secondary-red-color">&nbsp;*</span>
          </div>
          <div className='app-form-control-input'>
            <div className='app-form-control-prefix'>
              <EmailIcon />
            </div>
            <AppSelect
              className='app-form-input'
              value={ form.emailType }
              options={ emailTypeTranslations.current }
              simpleMode={ true }
              placeholder={ t('SelectEmail') }
              onOptionSelected={ (option: string) => form.setEmailType(option) }
            />
          </div>
        </div>
        <div className='app-form-control'>
          <div className='app-form-control-label app-form-contol-label-with-prefix-at-text-start'>
            <span>{ t('Date') }</span>
          </div>
          <div className='app-form-control-input app-position-relative'>
            <div className='app-form-control-prefix'>
              <CalendarIcon />
            </div>
            <input
              className='app-form-input'
              type='text'
              readOnly
              value={ formatDate(form.date, Formats.DateCommon) }
              placeholder={ t('Date') }
            />
            <div className='app-form-control-postfix'>
              <CalendarGrayIcon
                className='app-mt-11'
                onClick={ () => {
                  setCalendarVisible(!calendarVisible);
                } }
              />
            </div>
          </div>
          { calendarVisible && (
            <AppOutsideListener
              hidden={ true }
              onClick={ () => {
                setCalendarVisible(false);
              } }
            >
              <Calendar
                onChange={ e => {
                  form.setDate(new Date(e as Date));
                  setCalendarVisible(false);
                } }
                value={ form.date }
                className='app-maintenance-emails-calendar'
              />
            </AppOutsideListener>
          ) }
        </div>
        <div
          className='app-form-control'
          aria-invalid={ form.timeToBlurred && !!form.timeFromError }
        >
          <div className='app-form-control-label app-form-contol-label-with-prefix-at-text-start'>
            <span>{ t('TimeFrom') }</span>
            <span className="app-text-secondary-red-color">&nbsp;*</span>
          </div>
          <div className='app-form-control-input'>
            <div className='app-form-control-prefix'>
              <TimeIcon />
            </div>
            <input
              className='app-form-input'
              type='text'
              value={ form.timeFrom }
              placeholder={ t('hhmm') }
              onBlur={ () => form.setTimeFromBlurred(true) }
              onChange={ e => form.setTimeFrom(e.target.value) }
            />
          </div>
          { form.timeFromBlurred && form.timeFromError && (
            <div className='app-form-error app-form-error-with-prefix'>
              { form.timeFromError }
            </div>
          ) }
        </div>
        <div
          className='app-form-control'
          aria-invalid={ form.timeToBlurred && !!form.timeToError }
        >
          <div className='app-form-control-label app-form-contol-label-with-prefix-at-text-start'>
            <span>{ t('TimeTo') }</span>
            <span className="app-text-secondary-red-color">&nbsp;*</span>
          </div>
          <div className='app-form-control-input'>
            <div className='app-form-control-prefix'>
              <TimeIcon />
            </div>
            <input
              className='app-form-input'
              type='text'
              value={ form.timeTo }
              placeholder={ t('hhmm') }
              onBlur={ () => form.setTimeToBlurred(true) }
              onChange={ e => form.setTimeTo(e.target.value) }
            />
          </div>
          { form.timeToBlurred && form.timeToError && (
            <div className='app-form-error app-form-error-with-prefix'>
              { form.timeToError }
            </div>
          ) }
        </div>
        <div className='app-form-control'>
          <div className='app-form-control-label app-form-contol-label-with-prefix-at-text-start'>
            <span>{ t('ExpectedDuration') }</span>
          </div>
          <div className='app-form-control-input'>
            <div className='app-form-control-prefix'>
              <DurationIcon />
            </div>
            <input
              className='app-form-input'
              type='text'
              disabled
              readOnly
              value={ form.expectedDuration }
              placeholder={ t('ExpectedDuration') }
            />
          </div>
        </div>
        <div className='app-d-flex app-justify-content-end app-gap-20'>
          <button
            type='button'
            onClick={ () => {
              form.reset();
            } }
            className='app-button app-secondary-button'
          >
            { t('Cancel') }
          </button>
          <button disabled={ !form.isValid } type='submit' className='app-button app-primary-button'>
            { t('Send') }
          </button>
        </div>
      </form>
    </MaintenanceEmailsShell>
  );
}
