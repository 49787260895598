import { SortOrder } from "../appUIFramework/hooks/useSortStringOptions";
import { getUrlWithQueryParams, httpGetJson } from "../backend/http/http";
import { Urls } from "../backend/urls";
import { useSWRAndHandleErrors } from "./swr/useSWRAndHandleErrors";

export enum InvoicePaymentType {
  Unknown = 0,
  Auto = 1,
  Manual = 2,
}

export enum InvoiceStatus {
  Unknown = 0,
  Unpaid = 1,
  Pending = 2,
  Paid = 3,
  Failed = 4,
  Overdue = 5,
}

export enum CurrencyCode {
    GBP = 0,
    EUR = 1,
    USD = 2,
}

export enum CurrencyCodeString {
    GBP = 'GBP',
    EUR = 'EUR',
    USD = 'USD'
}
export enum CurrencyCodeSymbol {
    GBP = '£',
    EUR = '€',
    USD = '$'
}

export interface IInvoice {
  no: number;
  date: string;
  total: number;
  currency: CurrencyCode;
  type: InvoicePaymentType;
  status: InvoiceStatus;
}

export interface IInvoicesFilter {
  customerReference?: string;
  invoiceNoSearch?: string;
  application: 'EntryApp';
}

export interface IInvoicesQueryParams extends IInvoicesFilter {
  dateSort: SortOrder;
}

export interface IInvoicesTotalNumber {
  totalNumber: number;
}

export function useInvoicesTotalNumber(queryParams: IInvoicesFilter) {
  const url = Urls.InvoicesTotalNumber;
  const urlWithFilter = getUrlWithQueryParams(url, queryParams);
  const { data } = useSWRAndHandleErrors<IInvoicesTotalNumber>(urlWithFilter, httpGetJson);

  return data?.totalNumber;
}
