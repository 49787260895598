import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../../../assets/icons/Close-icon.svg';
import { ReactComponent as MyPaxtonIcon } from '../../../assets/my-paxton.svg';
import { closePopup } from '../popup/AppPopup';

const VIDEO_IDS = [
  'm0QVBLIqQso', // paxton entry 2021
  'O_nebL0TyJM', // paxton installer app
  'gY6Aerl9nGY', // paxton key app for Paxton 10
  'v2Tj_mlbUcc', // Paxton connect - Site management
  'El4MuowV438', // Introducing to entry audi monitors
];

const DEFAULT_VIDEO_ID = VIDEO_IDS[0];

const getVideoEmbed = (id: string) => `https://www.youtube.com/embed/${id}`;

export default function AppHelpPopup() {
  const { t } = useTranslation();
  const [videoId, setVideoId] = useState<string | null>(DEFAULT_VIDEO_ID);
  const isVideoIdNotNull = videoId != null;
  const clearVideoId = () => setVideoId(null);

  return (
    <div className="app-popup-content app-help-popup-content">
      <div className="app-text-align-right app-mt-33 app-mr-38">
        <CloseIcon onClick={closePopup} />
      </div>
      <div className="app-help-popup-content-header">
        { isVideoIdNotNull
          ? (
            <>
              <div className="app-d-flex app-font-20 app-weight-600 app-uppercase app-ml-99 app-mb-7">
                { t('Popup_WelcomeTo') }
                <MyPaxtonIcon className="app-w-130 app-h-31" />
              </div>
              <div className="app-font-20 app-ml-99 app-mb-32">{ t('Popup_WatchGettingStartedVideo') }</div>
            </>
          )
          : <div className="app-font-20 app-weight-600 app-uppercase app-ml-99 app-mb-26">{ t('Popup_VideoTutorials') }</div> }
      </div>
      <div className="app-ml-99">
        { isVideoIdNotNull ? (
          <div className="app-ml-28 app-single-video-container">
            <iframe
              className="app-single-video"
              src={getVideoEmbed(videoId)}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
            <div className="app-d-flex app-mt-26 app-justify-content-between app-mb-36">
              <button
                type="button"
                onClick={clearVideoId}
                className="app-primary-button app-mr-9"
              >
                { t('Popup_MoreVideos') }
              </button>
              <button
                type="button"
                onClick={closePopup}
                className="app-secondary-button"
              >
                { t('Popup_CloseHelp') }
              </button>
            </div>
          </div>
        )
          : (
            <div className="app-help-video-items">
              { VIDEO_IDS.map((id) => (
                <div
                  className="app-help-video-item"
                  role="button"
                  onClickCapture={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setVideoId(id);
                  }}
                  onKeyDown={() => setVideoId(id)}
                  tabIndex={0}
                >
                  <iframe
                    width="312"
                    height="228"
                    src={getVideoEmbed(id)}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                  <div className="app-help-video-item-title">
                    Some title
                  </div>
                </div>
              )) }
            </div>
          ) }

      </div>
    </div>
  );
}
