import { useEffect, createContext, useContext, useState, ReactNode } from 'react';

export enum MockActivation {
  Disabled = 'Disabled',
  PassWithMock = 'PassWithMock',
  PassWithExpiredMock = 'PassWithExpiredMock',
  Fail = 'Fail',
}

export enum MockDeactivation {
  Disabled = 'Disabled',
  PassWithMock = 'PassWithMock',
  Fail = 'Fail',
}

export enum MockSessionInfo {
  Disabled = 'Disabled',
  PassWithMock = 'PassWithMock',
  Fail = 'Fail',
}

const wsNegotiateUrl = process.env.REACT_APP_P10_WS_NEGOTIATE || '';
if(!wsNegotiateUrl) {
  throw new Error('REACT_APP_P10_NEGOTIATE is required');
}

export class SupportServerAccessFeatureFlags {
  public isSupportServerAccessEnabled = false;
  public isDevModeEnabled = false;
  public negotiateBaseUrl = wsNegotiateUrl;
  public mockSessionInfo = MockSessionInfo.PassWithMock;
  public mockActivation = MockActivation.PassWithMock;
  public mockDeactivation = MockDeactivation.PassWithMock;
}

const featureFlagsInstance = new SupportServerAccessFeatureFlags();

const FeatureFlagsContext = createContext<{ featureFlags: SupportServerAccessFeatureFlags, rerender: () => void }>({
  featureFlags: featureFlagsInstance,
  rerender: () => { },
});

// should be called once at very top of application
export function useSupportServerAccessFeatureFlags() {
  const { featureFlags, rerender } = useContext(FeatureFlagsContext);

  return { featureFlags, rerenderFlags: rerender };
}

const localStorageKey = 'supportServerAccessFeatureFlags';
export default function SupportServerAccessFeatureFlagsProvider({ children }: { children: ReactNode }) {
  const [featureFlags, setFeatureFlags] = useState({ ...featureFlagsInstance });
  const [loadedFromLocalStorage, setLoadedFromLocalStorage] = useState(false);

  useEffect(() => {
    const storedFeatureFlags = localStorage.getItem(localStorageKey);
    if (storedFeatureFlags && !loadedFromLocalStorage) {
      setFeatureFlags(JSON.parse(storedFeatureFlags));
      setLoadedFromLocalStorage(true);
    }
  }, [featureFlags, loadedFromLocalStorage]);

  useEffect(() => {
    // call this in console to enable the feature flags
    // window.enableSupportServerAccessFeatureFlags()
    const enableSupportServerAccessFeatureFlags = () => {
      featureFlags.isSupportServerAccessEnabled = true;
      featureFlags.isDevModeEnabled = true;
      featureFlags.mockSessionInfo = MockSessionInfo.Disabled;
      featureFlags.mockActivation = MockActivation.Disabled;
      featureFlags.mockDeactivation = MockDeactivation.Disabled;
      setFeatureFlags(prev => ({ ...prev }));
      localStorage.setItem(localStorageKey, JSON.stringify(featureFlags));
    };
    (window as any).enableSupportServerAccessFeatureFlags = enableSupportServerAccessFeatureFlags;

    // call this in console to disable the feature flags
    // window.disableSupportServerAccessFeatureFlags()
    const disableSupportServerAccessFeatureFlags = () => {
      featureFlags.isSupportServerAccessEnabled = false;
      featureFlags.isDevModeEnabled = false;
      featureFlags.mockSessionInfo = MockSessionInfo.Disabled;
      featureFlags.mockActivation = MockActivation.Disabled;
      featureFlags.mockDeactivation = MockDeactivation.Disabled;
      setFeatureFlags(prev => ({ ...prev }));
      localStorage.setItem(localStorageKey, JSON.stringify(featureFlags));
    };
    (window as any).disableSupportServerAccessFeatureFlags = disableSupportServerAccessFeatureFlags;
  }, [featureFlags]);

  return (
    <FeatureFlagsContext.Provider value={{ featureFlags, rerender: () => setFeatureFlags(prev => ({ ...prev })) }}>
      {children}
    </FeatureFlagsContext.Provider>
  );
}
