import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export enum SortOrder {
  Default = 1,
  Ascending = 2,
  Descending = 3,
}

export enum SortStringOptions {
  AtoZ = 'atoz',
  ZtoA = 'ztoa',
}

export function getSortOrderString(sortOrder?: SortOrder): SortStringOptions | undefined {
  switch (sortOrder) {
    case SortOrder.Ascending:
      return SortStringOptions.AtoZ;
    case SortOrder.Descending:
      return SortStringOptions.ZtoA;
    default:
      return undefined;
  }
}

export function getSortOrderFromString(sortString: SortStringOptions): SortOrder {
  switch (sortString) {
    case SortStringOptions.AtoZ:
      return SortOrder.Ascending;
    case SortStringOptions.ZtoA:
      return SortOrder.Descending;
    default:
      return SortOrder.Default;
  }
}

export function useSortStringOptions(): {
  options: SortStringOptions[];
  getOptionLabel: (option: SortStringOptions) => string;
} {
  const { t } = useTranslation();
  const [sortSiteNameOptions] = useState<Record<SortStringOptions, string>>({
    [SortStringOptions.AtoZ]: t('ATOZ'),
    [SortStringOptions.ZtoA]: t('ZTOA'),
  });

  const options = Object.keys(sortSiteNameOptions) as SortStringOptions[];
  const getOptionLabel = (option: SortStringOptions) => sortSiteNameOptions[option];

  return { options, getOptionLabel };
}
